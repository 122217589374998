import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminLayout from "../Components/Layouts/AdminLayout/AdminLayout";
// import Timesheet from "../Pages/Admin/Inputs";

import Login from "../Pages/Login";
import MobileLayout from "../Components/Layouts/MobileLayout/MobileLayout";
import Timesheet from "../Pages/TimeSheet";
import Home from "../Pages/Home";
import AdminTimesheet from "../Pages/Admin/TimeSheet";
import Profile from "../Pages/profile";
import NoData from "../Pages/NoData";
import ChangePassword from "../Pages/ChangePassword";
import ForgotPassword from "../Pages/ForgetPassword";
import Register from "../Pages/Register";
import CompanyAdminLayout from "../Components/Layouts/CompanyAdminLayout/CompanyAdminLayout";
import Dashboard from "../Pages/CompanyAdmin/Dashboard";
import Projects from "../Pages/CompanyAdmin/Projects";
import Tickets from "../Pages/CompanyAdmin/Tickets";
import CompanyTimesheet from "../Pages/CompanyAdmin/TimeSheets";
import ServiceTimesheet from "../Pages/CompanyAdmin/ServiceTimesheets";
import Sop from "../Pages/Sop";
import Service from "../Pages/Service";

type Props = {};

const Router = (props: Props) => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />

      <Route path="/forgot" element={<ForgotPassword />} />

      <Route path="/changepassword" element={<ChangePassword />} />

      <Route path="/user" element={<MobileLayout />}>
        <Route path="/user/home" element={<Home />} />
        <Route path="/user/service" element={<Service />} />
        <Route path="/user/timesheet" element={<Timesheet />} />
        <Route path="/user/profile" element={<Profile />} />
      </Route>

      <Route path="/admin" element={<AdminLayout />}>
        <Route path="/admin/timesheet" element={<AdminTimesheet />} />
      </Route>

      <Route path="/company" element={<CompanyAdminLayout />}>
        <Route path="/company/admin" element={<Dashboard />} />
        <Route path="/company/projects" element={<Projects />} />
        <Route path="/company/tickets" element={<Tickets />} />
        <Route path="/company/timesheet" element={<CompanyTimesheet />} />
        <Route path="/company/timesheet/service" element={<ServiceTimesheet />} />

      </Route>
      <Route path="/sop" element={<Sop />} />


      <Route path="*" element={<NoData />} />
    </Routes>
  );
};

export default Router;

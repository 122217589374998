// import { BASE_URL } from "./../Constants/apiConstant.js";
import { api } from "./config/axiosConfig.js";
import { defineCancelApiObject } from "./config/axiosUtils.js";
import axios from "axios";


const config = {
    'Content-Type': 'application/json',

}

export const AdminAPI = {


    migrateUsers: async (cancel = false) => {
        const response = await api.request({
            url: `/user/migrate`,
            method: "GET",
            headers: { ...config, 'Authorization': `Bearer ${localStorage.getItem('auth')}`, },
            signal: cancel ? cancelApiObject[this.post.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },


    getCompanyUsers: async (cancel = false) => {
        const response = await api.request({
            url: `/user/users`,
            method: "GET",
            headers: { ...config, 'Authorization': `Bearer ${localStorage.getItem('auth')}`, },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },


    getProject: async (data, cancel = false) => {
        const response = await api.request({
            url: `/project`,
            method: "GET",
            headers: { ...config, 'Authorization': `Bearer ${localStorage.getItem('auth')}`, },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    getService: async (cancel = false) => {
        const response = await api.request({
            url: `/service`,
            method: "GET",
            headers: { ...config, 'Authorization': `Bearer ${localStorage.getItem('auth')}`, },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    getProjectTicket: async (id, cancel = false) => {
        const response = await api.request({
            url: `/project/tickets/` + id,
            method: "GET",
            headers: { ...config, 'Authorization': `Bearer ${localStorage.getItem('auth')}`, },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },


    getServiceTicket: async (cancel = false) => {
        const response = await api.request({
            url: `/service/ticket`,
            method: "GET",
            headers: { ...config, 'Authorization': `Bearer ${localStorage.getItem('auth')}`, },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    getServiceBoard: async (cancel = false) => {
        const response = await api.request({
            url: `/service/board`,
            method: "GET",
            headers: { ...config, 'Authorization': `Bearer ${localStorage.getItem('auth')}`, },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    getUsers: async (cancel = false) => {
        const response = await api.request({
            url: `/user/list`,
            method: "GET",
            headers: { ...config, 'Authorization': `Bearer ${localStorage.getItem('auth')}`, },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    getTimeSheets: async (cancel = false) => {
        const response = await api.request({
            url: `/time/timesheets`,
            method: "GET",
            headers: { ...config, 'Authorization': `Bearer ${localStorage.getItem('auth')}`, },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    getServiceTimesheet: async (cancel = false) => {
        const response = await api.request({
            url: `/time/timesheets/service`,
            method: "GET",
            headers: { ...config, 'Authorization': `Bearer ${localStorage.getItem('auth')}`, },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    postTimesheetPunchIn: async (data, cancel = false) => {
        const response = await api.request({
            url: `/time/punchIn`,
            method: "POST",
            headers: { ...config, 'Authorization': `Bearer ${localStorage.getItem('auth')}`, },
            data: data,
            signal: cancel ? cancelApiObject[this.post.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    postTimesheetPunchOut: async (id, data, cancel = false) => {
        const response = await api.request({
            url: `/time/punchOut/` + id,
            method: "POST",
            headers: { ...config, 'Authorization': `Bearer ${localStorage.getItem('auth')}`, },
            data: data,
            signal: cancel ? cancelApiObject[this.post.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    updateTimesheetNotes: async (id, data, cancel = false) => {
        const response = await api.request({
            url: `/time/timesheet/` + id,
            method: "PUT",
            headers: { ...config, 'Authorization': `Bearer ${localStorage.getItem('auth')}`, },
            data: data,
            signal: cancel ? cancelApiObject[this.post.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    postServiceTimesheetPunchIn: async (data, cancel = false) => {
        const response = await api.request({
            url: `/time/service/punchIn`,
            method: "POST",
            headers: { ...config, 'Authorization': `Bearer ${localStorage.getItem('auth')}`, },
            data: data,
            signal: cancel ? cancelApiObject[this.post.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    postServiceTimesheetPunchOut: async (id, data, cancel = false) => {
        const response = await api.request({
            url: `/time/service/punchOut/` + id,
            method: "POST",
            headers: { ...config, 'Authorization': `Bearer ${localStorage.getItem('auth')}`, },
            data: data,
            signal: cancel ? cancelApiObject[this.post.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    updateServiceTimesheetNotes: async (id, data, cancel = false) => {
        const response = await api.request({
            url: `/time/service/notes/` + id,
            method: "PUT",
            headers: { ...config, 'Authorization': `Bearer ${localStorage.getItem('auth')}`, },
            data: data,
            signal: cancel ? cancelApiObject[this.post.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    postCheckTimesheet: async (data, cancel = false) => {
        const response = await api.request({
            url: `/time/check`,
            method: "POST",
            headers: { ...config, 'Authorization': `Bearer ${localStorage.getItem('auth')}`, },
            data: data,
            signal: cancel ? cancelApiObject[this.post.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    postTimesheetAddNotes: async (id, data, cancel = false) => {
        const response = await api.request({
            url: `/time/notes/` + id,
            method: "POST",
            headers: { ...config, 'Authorization': `Bearer ${localStorage.getItem('auth')}`, },
            data: data,
            signal: cancel ? cancelApiObject[this.post.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    uploadFile: async (data, cancel = false) => {
        const response = await api.request({
            url: `/common/file/upload`,
            method: "POST",
            headers: { ...config, 'Authorization': `Bearer ${localStorage.getItem('auth')}`, },
            data: data,
            signal: cancel ? cancelApiObject[this.post.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    getMyTimeSheet: async (cancel = false) => {
        const response = await api.request({
            url: `/time/sheet`,
            method: "GET",
            headers: { ...config, 'Authorization': `Bearer ${localStorage.getItem('auth')}`, },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    }


}

const cancelApiObject = defineCancelApiObject(AdminAPI);
